import { Person, Stars, LocalMall, Store, Redeem } from '@material-ui/icons';
import { ListItem } from 'design-system';
import { useIntl } from 'react-intl';

export const SearchInstructions = ({ showRewardSuggestion }: { showRewardSuggestion: boolean }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.customer' })}
        icon={<Person />}
        text={formatMessage({ id: 'searchInstructions.customerEmailOrCognitoID' })}
      />
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.customer' })}
        icon={<Stars />}
        text={formatMessage({ id: 'searchInstructions.loyaltyID' })}
      />
      {showRewardSuggestion && (
        <ListItem
          iconTooltipText={formatMessage({ id: 'searchInstructions.customer' })}
          icon={<Stars />}
          text={formatMessage({ id: 'searchInstructions.rewardCardIDorBarcode' })}
        />
      )}
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.order' })}
        icon={<LocalMall />}
        text={formatMessage({ id: 'searchInstructions.RBIOrderID' })}
      />
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.store' })}
        icon={<Store />}
        text={formatMessage({ id: 'searchInstructions.storeNumberSanityIDorAddress' })}
      />
      <ListItem
        iconTooltipText={formatMessage({ id: 'searchInstructions.giftCard' })}
        icon={<Redeem />}
        text={formatMessage({ id: 'searchInstructions.cardNumber' })}
      />
    </>
  );
};
