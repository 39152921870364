import { ListAlt, LocalMall, LocalOffer, People, Stars, Store } from '@material-ui/icons';
import { IntlFormatters } from 'react-intl';

import BKLogo from 'components/logos/bk';
import { BK_BBQ_BROWN } from 'state/theme/colors';

import { routes } from '../utils/routing';
import { IBrandConfigs } from './brand-config';

export const getBkConfigs = (formatMessage: IntlFormatters['formatMessage']): IBrandConfigs => ({
  links: [
    {
      name: formatMessage({ id: 'sidebar.customers' }),
      route: routes.customer,
      Icon: People,
    },
    {
      name: formatMessage({ id: 'sidebar.orders' }),
      route: routes.orders,
      Icon: LocalMall,
    },
    {
      name: formatMessage({ id: 'sidebar.supportHistory' }),
      route: routes.supportHistory,
      Icon: ListAlt,
    },
    {
      name: formatMessage({ id: 'sidebar.offers' }),
      route: routes.offers,
      Icon: LocalOffer,
    },
    {
      name: formatMessage({ id: 'sidebar.loyalty' }),
      route: routes.intlLoyalty,
      Icon: Stars,
    },
    {
      name: formatMessage({ id: 'sidebar.stores' }),
      route: routes.store,
      Icon: Store,
    },
  ],
  Logo: BKLogo,
  theme: {
    brandColor: BK_BBQ_BROWN,
  },
  intlLoyaltyEnabled: true,
  hasPhysicalIntlLoyaltyCard: false,
  loyaltyPointsIssuanceRange: {
    USA: {
      min: 1,
      max: 1500,
      step: 1,
    },
    CAN: {
      min: 1,
      max: 1500,
      step: 1,
    },
    ESP: {
      min: 1,
      max: 20000,
      step: 1,
    },
    PRT: {
      min: 1,
      max: 20000,
      step: 1,
    },
    DEFAULT: {
      min: 1,
      max: 1500,
      step: 1,
    },
  },
  loyaltyPointsPerCurrency: {
    USA: 100,
    CAN: 100,
    DEFAULT: 100,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
});
