import gql from 'graphql-tag';

import { CartEntryFragment } from './fragments';

export const FetchOrder = gql`
  query Order($rbiOrderId: String!) {
    Order(rbiOrderId: $rbiOrderId) {
      id: rbiOrderId
      __typename
      cart {
        cartEntries {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                  children {
                    ...CartEntryFragment
                  }
                }
              }
            }
          }
        }
        fees {
          name
          priceCents
          quantity
          totalCents
        }
        discounts {
          name
          value
        }
        donations {
          id
          name
          totalCents
        }
        feCountryCode
        payment {
          cardType
          ccLast4
          fdCorrelationId
          paymentMethodBrand
        }
        platform
        serviceMode
        storeAddress {
          addressLine1
          addressLine2
          city
          country
          state
        }
        storeDetails {
          timezone
        }
        storeId
        subTotalCents
        taxCents
        totalCents
        userId #  currently required for logging refund events to mParticle
        guestId
        guestDetails {
          isoCountryCode
          guestEmail
        }
      }
      errors {
        message
        code
      }
      createdAt
      customer {
        id: customerId
        email
        customerId
        name
        fdCustomerId
        isoCountryCode
      }
      delivery {
        driver {
          name
          phoneNumber
        }
        dropoff {
          beforeTime
          addressLine1
          addressLine2
          city
          state
        }
        feeCents
        feeDiscountCents
        serviceFeeCents
        serviceFeeDiscountCents
        status
        tipCents
      }
      loyaltyTransaction {
        pointsEarned
        pointsBalance
        pointsUsed
        bonusPointsEarned
        rewardsUsed
      }
      deliveryTimeline {
        status
        time
      }
      order {
        refund {
          adminUserEmail
          approvedAmount
          primaryReason
          refundedItems {
            name
          }
          secondaryReason
          subtotal
          tax
          transactionDateTime
        }
      }
      remediations {
        amount
        created
        displayName
        remediationReason
        remediationType
        remediationComments
        supportEmail
        transactionId
        transactionType
        userId
      }
      paymentStatus
      paymentVersion
      rbiOrderId
      status
      userId
    }
  }
  ${CartEntryFragment}
`;
