import { ListAlt, LocalMall, LocalOffer, People, Stars, Store } from '@material-ui/icons';
import { IntlFormatters } from 'react-intl';

import THLogo from 'components/logos/th';
import { TIM_HORTONS_DARK_RED } from 'state/theme/colors';

import { routes } from '../utils/routing';
import { IBrandConfigs } from './brand-config';

export const getThConfigs = (formatMessage: IntlFormatters['formatMessage']): IBrandConfigs => ({
  links: [
    {
      name: formatMessage({ id: 'sidebar.customers' }),
      route: routes.customer,
      Icon: People,
    },
    {
      name: formatMessage({ id: 'sidebar.orders' }),
      route: routes.orders,
      Icon: LocalMall,
    },
    {
      name: formatMessage({ id: 'sidebar.supportHistory' }),
      route: routes.supportHistory,
      Icon: ListAlt,
    },
    {
      name: formatMessage({ id: 'sidebar.offers' }),
      route: routes.offers,
      Icon: LocalOffer,
    },
    {
      name: formatMessage({ id: 'sidebar.loyalty' }),
      route: routes.intlLoyalty,
      Icon: Stars,
    },
    {
      name: formatMessage({ id: 'sidebar.stores' }),
      route: routes.store,
      Icon: Store,
    },
  ],
  Logo: THLogo,
  theme: {
    brandColor: TIM_HORTONS_DARK_RED,
  },
  intlLoyaltyEnabled: true,
  loyaltyPointsIssuanceRange: {
    USA: {
      min: 10,
      max: 2000,
      step: 1,
    },
    CAN: {
      min: 10,
      max: 2000,
      step: 1,
    },
    ESP: {
      min: 1,
      max: 20000,
      step: 1,
    },
    PRT: {
      min: 1,
      max: 20000,
      step: 1,
    },
    DEFAULT: {
      min: 10,
      max: 250,
      step: 1,
    },
  },
  loyaltyPointsWithdrawRange: {
    USA: {
      min: 1,
      max: 5000,
    },
    CAN: {
      min: 1,
      max: 5000,
    },
  },
  loyaltyPointsPerCurrency: {
    USA: 200,
    CAN: 200,
    DEFAULT: 50,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
});

export default getThConfigs;
