import { ServiceMode, DriveThruLaneType } from 'types';
import { IAddress } from 'types/address';

import { ErrorDetails, Maybe, SanityOffer } from '../../services/graphql/src/generated/graphql';
import { AUTOMATED_REFUND_AGENT_EMAIL } from '../components/utils/constants';

enum CountryAbbreviation {
  US = 'US',
  CAN = 'CAN',
  UNKNOWN = 'UNKNOWN',
}

enum BarcodePrefix {
  US = '0464',
  US1 = '0474',
  CAN = '0463',
  CAN1 = '0473',
}

export enum SupportUserPermissions {
  COMPLIANCE = 'compliance-agent',
}

export function countryAbbreviationForBarcode(barcode: string | undefined) {
  if (!barcode) {
    return CountryAbbreviation.UNKNOWN;
  }
  const prefix = barcode.slice(0, 4);
  switch (prefix) {
    case BarcodePrefix.US:
    case BarcodePrefix.US1:
      return CountryAbbreviation.US;
    case BarcodePrefix.CAN:
    case BarcodePrefix.CAN1:
      return CountryAbbreviation.CAN;
    default:
      return CountryAbbreviation.UNKNOWN;
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: DriveThruLaneType) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

export function centsToDollars(price: number = 0) {
  return price / 100;
}

export function centsToDollarString(cents: number = 0) {
  return centsToDollars(cents).toFixed(2);
}

export function dateDifference(startDate: Date, endDate: Date) {
  return Math.abs(startDate.getTime() - endDate.getTime()) / (24 * 60 * 60 * 1000);
}

export function zeroPad(num: number | undefined) {
  const padLength = 2;
  return (num ?? 0).toString().padStart(padLength, '0');
}

export function formatAddress(address: IAddress) {
  const { addressLine1, addressLine2, city, state } = address;
  return [addressLine1, addressLine2, city, state].filter(Boolean).join(' - ');
}

export function paymentMethodBrandTranslationMap(paymentMethodBrand: string) {
  const translatePaymentMethodBrand = {
    SODEXO: 'orderDetails.paymentMethodBrand.sodexo',
    SODEXO_VOUCHER: 'orderDetails.paymentMethodBrand.sodexo_voucher',
    CHEQUE_GOURMET: 'orderDetails.paymentMethodBrand.cheque_gourmet',
    CHEQUE_GOURMET_VOUCHER: 'orderDetails.paymentMethodBrand.cheque_gourmet_voucher',
    PAYMENT_ON_DELIVERY_CARD: 'orderDetails.paymentMethodBrand.payment_on_delivery_card',
    BIZUM: 'orderDetails.paymentMethodBrand.bizum',
    WAYLET: 'orderDetails.paymentMethodBrand.waylet',
    MAESTRO: 'orderDetails.paymentMethodBrand.maestro',
    TWINT: 'orderDetails.paymentMethodBrand.twint',
    APPLEPAYLINK: 'orderDetails.paymentMethodBrand.apple_pay_link', // When processed via Paycomet PSP, apple pay uses payment method brand
    TICKET_RESTAURANT_CARD: 'orderDetails.paymentMethodBrand.ticket_restaurant_card',
    TICKET_RESTAURANT_VOUCHER: 'orderDetails.paymentMethodBrand.ticket_restaurant_voucher',
  };

  return paymentMethodBrand in translatePaymentMethodBrand
    ? translatePaymentMethodBrand[paymentMethodBrand]
    : 'orderDetails.paymentMethodBrand.unknown';
}

export function autoRefundEmail(email: string | undefined): string | undefined {
  return email === AUTOMATED_REFUND_AGENT_EMAIL ? 'Auto' : undefined;
}

export const buildErrorMessages = (errors: readonly Maybe<ErrorDetails>[]) => {
  let formattedError = '';
  errors?.length &&
    errors.forEach((error: Maybe<ErrorDetails>) => {
      formattedError += `${error?.message}-${error?.code}\n`;
    });
  return formattedError;
};

export const sortOffers = (offersList: Maybe<SanityOffer>[]) => {
  const sortedList = [...offersList];
  sortedList.sort((a, b) => a?.name?.localeCompare(b?.name!)!);
  return sortedList;
};

export const hasPermission = (permissions: string[] = [], permission: SupportUserPermissions) => {
  return permissions.includes(permission);
};
