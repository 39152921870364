import gql from 'graphql-tag';

export const INTLLoyaltyOffers = gql`
  query intlLoyaltyOffersByLoyaltyId($loyaltyId: String!) {
    intlLoyaltyOffersByLoyaltyId(loyaltyId: $loyaltyId) {
      id
      name
      sourceId
      createdAt
      metadata {
        source
        remediationReason
        comments
        agentEmail
        orderId
      }
      endDate
    }
  }
`;
