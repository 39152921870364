import { ListAlt, LocalMall, LocalOffer, People, Stars, Store } from '@material-ui/icons';
import { IntlFormatters } from 'react-intl';

import FHSLogo from 'components/logos/fhs';
import { FIREHOUSE_SUBS_DARK_RED } from 'state/theme/colors';

import { routes } from '../utils/routing';
import { IBrandConfigs } from './brand-config';

export const getFhsConfigs = (formatMessage: IntlFormatters['formatMessage']): IBrandConfigs => ({
  links: [
    {
      name: formatMessage({ id: 'sidebar.customers' }),
      route: routes.customer,
      Icon: People,
    },
    {
      name: formatMessage({ id: 'sidebar.orders' }),
      route: routes.orders,
      Icon: LocalMall,
    },
    {
      name: formatMessage({ id: 'sidebar.supportHistory' }),
      route: routes.supportHistory,
      Icon: ListAlt,
    },
    {
      name: formatMessage({ id: 'sidebar.offers' }),
      route: routes.offers,
      Icon: LocalOffer,
    },
    {
      name: formatMessage({ id: 'sidebar.loyalty' }),
      route: routes.intlLoyalty,
      Icon: Stars,
    },
    {
      name: formatMessage({ id: 'sidebar.stores' }),
      route: routes.store,
      Icon: Store,
    },
  ],
  Logo: FHSLogo,
  theme: {
    brandColor: FIREHOUSE_SUBS_DARK_RED,
  },
  intlLoyaltyEnabled: true,
  loyaltyPointsIssuanceRange: {
    USA: {
      min: 10,
      max: 15000,
      step: 10,
    },
    CAN: {
      min: 10,
      max: 15000,
      step: 10,
    },
    ESP: {
      min: 1,
      max: 1500,
      step: 1,
    },
    PRT: {
      min: 1,
      max: 1500,
      step: 1,
    },
    DEFAULT: {
      min: 10,
      max: 15000,
      step: 10,
    },
  },
  loyaltyPointsPerCurrency: {
    USA: 1000,
    CAN: 1000,
    DEFAULT: 1000,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
});

export default getFhsConfigs;
